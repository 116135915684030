import React from "react"
import { Link } from "gatsby"
import Layout from "components/layout"
import Seo from "components/seo"
import * as FadeIn from "components/fadein"
import { Fv } from "components/fv"

const CleaningSneakerPage = () => {
  return (
    <Layout>
      <div className="sneaker-cleaning design-style">
        <Fv
          pcFv="https://balukoweb.blob.core.windows.net/images/laundry-service/sneaker/mv-img.jpg"
          spFv="https://balukoweb.blob.core.windows.net/images/laundry-service/sneaker/sp-mv-img.jpg"
          fvTitle="Sneaker Cleaning"
          color="black"
        />
        <FadeIn.Up>
          <div className="lead-area">
            {/* PC */}
            <h2 className="pc title tx-center">
              大切なフットウェアをより良い状態で
              <br />
              長く楽しんでいただくためのスペシャルケア
            </h2>
            {/* SP-L */}
            <h2 className="sp title tx-center">
              大切なフットウェアをより良い状態で
              <br />
              長く楽しんでいただくためのスペシャルケア
            </h2>

            <p className="lead tx-center fw-b">
              ご自宅でのお洗濯では落としきれない汚れや臭いを落とすスニーカー専用クリーニング。<br />
              1点1点お客さまのご要望に合わせた最適なプログラムをご提案します。
            </p>
          </div>
        </FadeIn.Up>
        <div className="title-area-style tx-center">
          <h2 className="title e">Price</h2>
          <p className="sub-title fw-b">価格</p>
        </div>
        <FadeIn.Up>
          <div className="main-contents">
            <div className="course-list">
              <div className="course-list-inner">
                <h2 className="title tx-center fw-b">コース</h2>
                <div className="course-list-contents">
                  <div className="course-list-contents-item">
                    <div className="course-list-contents-left">
                      <div>
                        <p className="flex-item">
                          <span className="course-name fw-b">マシンウォッシュ<div className="course-name-sub">Machine wash</div></span>
                          <span className="course-price fw-b">¥990</span>
                        </p>
                        <p className="course-about">
                          スニーカーを丸ごと水洗い
                        </p>
                      </div>
                    </div>
                    <div className="course-list-contents-right">
                      <p className="course-detail">
                        キャンバスやナイロン地、水洗い可能なレザーのスニーカーに。<br />
                        当店でクリーニング作業をおこないます。
                      </p>
                      <p className="course-detail-sub">※手仕上げはおこないません。</p>
                    </div>
                  </div>
                  <div className="course-list-contents-item">
                    <div className="course-list-contents-left">
                      <div>
                        <p className="flex-item">
                          <span className="course-name fw-b">ワンバイワン</span>
                          <span className="course-price fw-b">¥3,300</span>
                        </p>
                        <p className="course-about">
                          水洗いできないスニーカーやブランド品やヴィンテージ物などを美しく
                        </p>
                      </div>
                    </div>
                    <div className="course-list-contents-right">
                      <p className="course-detail">
                        水洗いによる色落ちやダメージを避けたいスニーカーの定期的なメンテナンスに。
                      </p>
                      <p className="course-detail-sub">※クリーニング工場で作業をおこないます。</p>
                      <p className="course-detail-sub">※仕上がりまで2~3週間程度かかります。</p>
                      <p className="course-detail-sub">※靴紐は外してのお返しになります。</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="option-list">
              <div className="option-list-inner">
                <h2 className="title tx-center fw-b">オプション</h2>
                <h3 className="sub-title tx-center fw-b">すべて、ワンバイワンコースのみ対象です。</h3>
                <div className="option-list-contents">
                  <div className="option-list-contents-item">
                    <div className="option-list-contents-left">
                      <p>
                        <span className="fw-b">簡易補色</span>
                        <br />
                        <span className="">¥2,200</span>
                      </p>
                    </div>
                    <div className="option-list-contents-right">
                      <p className="">
                        つま先とかかと部分のみ補色をおこないます。
                        <div className="">
                          ※本革・スウェードの靴のみ対象です。
                        </div>
                      </p>
                    </div>
                  </div>
                  <div className="option-list-contents-item">
                    <div className="option-list-contents-left">
                      <p>
                        <span className="fw-b">全体補色</span>
                        <br />
                        <span className="">¥3,300</span>
                      </p>
                    </div>
                    <div className="option-list-contents-right">
                      <p className="">
                        全体の補色をおこないます。
                        <div className="">
                          ※本革・スウェードの靴のみ対象です。
                        </div>
                      </p>
                    </div>
                  </div>
                  <div className="option-list-contents-item">
                    <div className="option-list-contents-left">
                      <p>
                        <span className="fw-b">撥水</span>
                        <br />
                        <span className="">¥700</span>
                      </p>
                    </div>
                    <div className="option-list-contents-right">
                      <p className="">
                        スニーカー表面に撥水剤を定着させ、水や日常の汚れからスニーカーを守ります。
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p className="annotation">
              ※価格はすべて税込です。
              <br />
              ※素材や損傷が激しいものなど、一部お受けできないものがございます。
              <br />
            </p>

            <div className="title-area-style tx-center">
              <h2 className="title e">Store</h2>
              <p className="sub-title fw-b">クリーニングサービス取扱い店舗</p>
            </div>
            <div className="store-contents">
              <div className="store-contents-inner">
                <ul className="store-contents-list">
                  <li className="store-contents-item">
                    <div className="store-contents-item-box">
                      <img
                        className="lazy pc"
                        src="https://balukoweb.blob.core.windows.net/images/cleaning/pc-store01-clipped.jpg"
                        width={413}
                        alt=""
                        loading="lazy"
                      />
                      <img
                        className="lazy sp"
                        src="https://balukoweb.blob.core.windows.net/images/cleaning/pc-store01-clipped.jpg"
                        width="100%"
                        alt=""
                        loading="lazy"
                      />
                      <div className="store-contents-item-box-detail">
                        <p className="store-name fw-b">代々木上原</p>
                        <p className="store-address">東京都渋谷区上原3-29-2</p>
                        <Link className="store-link" to="/baluko-uehara/">
                          店舗詳細
                        </Link>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </FadeIn.Up>
      </div>
    </Layout>
  )
}

export const Head = () => (
  <Seo
    title="Sneaker Cleaning － 大切なフットウェアをより良い状態に。"
    description="ご自宅でのお洗濯では落としきれない汚れや臭いを落とすスニーカー専用クリーニング。1点1点お客さまのご要望に合わせた最適なプログラムをご提案します。"
  />
)
export default CleaningSneakerPage
